<template>
  <div
    style="padding: 20px"
  >
  {{files}}
    <v-btn block @click="getTableStats" color="primary">
      recargar
    </v-btn>
    <v-layout wrap style="margin-top: 20px">
      <v-flex xs3
        v-for="table of data"
        :key="table.name"
      >
      <v-layout wrap style="padding: 5px">
        <v-flex xs6>
          <b>
            {{table.name}}
          </b>
        </v-flex>
        <v-flex xs6>
          {{table.count}}
        </v-flex>
      </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  data: () => ({
    data: null,
    files: null,
  }),
  mounted () {
    //this.getAllFiles()
    this.getTableStats()
  },
  methods: {
    getAllFiles () {
      api.getAllWithoutLimit ('dtouch', `v1/private/dev/files`)
        .then((response) => {
          this.files = response
        })
    },
    getTableStats () {
      api.getAllWithoutLimit ('dtouch', `v1/private/dev/table-stats`)
        .then((response) => {
          this.data = response.sort((a, b) => {
            return b.count - a.count
          })
        })
    },
  }
}
</script>

